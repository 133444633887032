<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="审批类型:">
                    <el-select v-model="filters.selectType">
                        <el-option
                        v-for="item in optionsData1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="流程名称:">
                    <el-input placeholder="请输入关键字" v-model="filters.ApproName"></el-input>
                </el-form-item>
                <el-form-item label="启用标识:">
                    <el-select v-model="filters.selectc">
                        <el-option
                        v-for="item in optionsData2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!--列表-->
        <el-table
            :data="dataPer"
            highlight-current-row
            row-key="ID"
            @current-change="selectCurrentRow"
            v-loading="listLoading"
            border
            v-if="isRouterAlive"
            style="width: 100%;"
        >
            <el-table-column type="index" label="序号" width="60" align="center">
            </el-table-column>
            <el-table-column prop="ProcessName" label="流程名称" width="" align="center">
            </el-table-column>
            <el-table-column prop="OrganizationInfoName" label="组织机构" width="" align="center">
            </el-table-column>
            <el-table-column prop="OrganizationInfoName" label="审批类型" width="" align="center">
                <template slot-scope="scope">
                    <el-tag :type="'success'" v-if="scope.row.ProcessType == 0">转为字典试题</el-tag>
                    <el-tag :type="'success'" v-if="scope.row.ProcessType == 1">变更字典试题</el-tag>
                    <el-tag :type="'success'" v-if="scope.row.ProcessType == 2">发布考试项目</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IsEnable" label="启用标识" align="center">
                <template slot-scope="scope">
                <el-tag
                    :type="!scope.row.IsEnable  ? 'danger' : 'success'"
                    disable-transitions
                >{{!scope.row.IsEnable ? "禁用":"启用"}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="Remarks" label="备注" width="" align="center">
            </el-table-column>
            <el-table-column prop="CreateTime" label="创建时间" width="" align="center" :formatter="formatCreateTime">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="150"
                align="center"
            >
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="primary" plain size="mini" icon="el-icon-notebook-1">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount"
            >
            </el-pagination>
        </el-col>

        <!--新增界面-->
        <el-dialog
            :title="addOrEdi?'新增':'编辑'"
            :visible.sync="addFormVisible"
            v-model="addFormVisible"
            :close-on-click-modal="false"
        >
            <el-form :model="addForm" label-width="150px" :rules="addFormRules" ref="addForm">
                <el-form-item label="流程名称：" prop="processName">
                    <el-input v-model="addForm.processName" @keyup.enter.native="enterKey(1)" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="审批类型：" prop="selectType">
                    <el-select v-model="addForm.selectType" style="width: 300px;">
                        <el-option
                            v-for="item in optionsData1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="启用标识：" prop="IsEnable">
                    <el-select v-model="addForm.IsEnable" style="width: 300px;">
                        <el-option
                            v-for="item in optionsData2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注：">
                    <el-input v-model="addForm.remarks" @keyup.enter.native="enterKey(1)" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <!--列表-->
            <el-button type="primary" @click.native="addNodesChi" icon="el-icon-plus" size="small" style="float: right;margin-right: 17px;">添加</el-button>
            <el-table
                :data="roles"
                row-key="nodeNum"
                :show-header="true"
                highlight-current-row
                v-loading="listLoading1"
                style="width: 100%;"
                class="nodeChiTable"
            >
                <el-table-column label="拖拽" width="60" align="center">
                    <template>
                        <el-button class="dropBtn" icon="el-icon-s-unfold" size="mini"></el-button>
                    </template>
                </el-table-column>
                <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
                <el-table-column label="名称" width align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.nodeName" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="250"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button @click="nodesSelPer(scope.row)" type="text" v-if="scope.row.noPer" size="mini" icon="el-icon-user-solid">{{scope.row.perTitle}}</el-button>
                        <el-button @click="nodesSelOrg(scope.row)" type="text" v-if="scope.row.noOrg" size="mini" icon="el-icon-s-management">{{scope.row.organTitle}}</el-button>
                        <el-button @click="nodesDel(scope.row,scope.$index)" type="danger" plain size="mini" icon="el-icon-delete">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--选择人/选择组织机构-->
        <el-dialog
            title="选择"
            width="60%"
            :visible.sync="selectFormVisible"
            v-model="selectFormVisible"
            :close-on-click-modal="false"
            @close="closeSelect"
        >
            <!-- 选择组织机构 -->
            <el-tree
                v-if="perOrOrgan == 'org'"
                class="filter-tree"
                :data="treeData"
                show-checkbox
                node-key="ID"
                ref="tree"
                v-loading="listLoading1"
                check-strictly
                :highlight-current='true'
                :check-on-click-node="true"
                :props="defaultProps"
                :default-expand-all="true"
                @check-change="getChecked"
                style="max-width: 700px;max-height: 650px;overflow: auto;"
            >
                <span class="custom-tree-node" slot-scope="{ node,data }" style="font-size:16px;">
                    <span>
                        <i :class="data.Icon"></i>&nbsp;&nbsp;{{ node.label }}
                    </span>              
                </span>
            </el-tree>
            <!-- 选择人 -->
            <!--顶部工具条-->
            <el-col :span="24" v-if="perOrOrgan == 'per'">
                <el-form :inline="true">
                    <el-form-item label="组织机构：" width>
                        <el-cascader
                            placeholder="请选择，支持搜索功能"
                            style="width: 100%"
                            v-model="OptionTreec"
                            :options="treeData"
                            :props="defaultProps1"
                            filterable
                            change-on-select
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label="" width>
                        <el-button type="primary" @click="searchPageList">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-row>
                <el-col :span="17">
                    <div style="" v-if="perOrOrgan == 'per'">
                        <!--列表-->
                        <el-table :data="users"
                            highlight-current-row 
                            @current-change="selectCurrentRow2"
                            v-loading="listLoading2"
                            style="width: 110%;"
                        >
                            <el-table-column type="index" label="序号" width="80"></el-table-column>
                            <el-table-column prop="Name" label="昵称" width=""></el-table-column>
                            <el-table-column prop="LoginAccount" label="登录名" width=""></el-table-column>
                            <!-- <el-table-column prop="PhoneNumber" label="手机号" width="" sortable></el-table-column> -->
                            <!-- <el-table-column prop="OrganizationInfoName" label="组织机构" width=""></el-table-column> -->
                            <el-table-column prop="RoleNames" label="角色" width="">
                                <template slot-scope="scope">
                                    <el-tag v-for="item in scope.row.RoleNames" :key="item.Id" >{{item}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="100"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <el-button @click="nodesSelPer2(scope.row)" type="text" size="mini" icon="el-icon-circle-plus">添加</el-button>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column prop="IsEnable" label="启用标识" width="" sortable>
                                <template slot-scope="scope">
                                    <el-tag
                                            :type="scope.row.IsEnable == true ? 'success' : 'danger'"
                                            disable-transitions>{{scope.row.IsEnable == true ? "正常":"禁用"}}
                                    </el-tag>
                                </template>
                            </el-table-column> -->
                        </el-table>
                        <!--底部工具条-->
                        <el-pagination
                            @size-change="handleSizeChange1"
                            @current-change="handleCurrentChange1"
                            :current-page="pages1.pageIndex"
                            :page-sizes="pages1.pageArr"
                            :page-size="pages1.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pages1.dataCount"
                        >
                        </el-pagination>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div style="margin-left:10px;" v-if="perOrOrgan == 'per'">
                    <!-- <div> -->
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>已选列表</span>
                            </div>
                            <div v-for="o in perSelectData" :key="o.index" class="text item" style="display: flex;justify-content: space-between;align-items: center;">
                                <div>{{ o.Name }}</div>
                                <div><el-button type="danger" icon="el-icon-delete" size="mini" @click="deleSelPer2(o)" circle></el-button></div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
            <div style="clear: both;" v-if="perOrOrgan == 'per'"></div>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button @click.native="selectFormVisible = false">取消</el-button> -->
                <el-button type="primary" @click.native="selectSubmit">保存</el-button>
            </div>
        </el-dialog>
        <!--查看-->
        <el-dialog
            title="流程图"
            :visible.sync="viewDialog"
            v-model="viewDialog"
            :close-on-click-modal="false"
            class="viewFlow"
        >
            <div class="starAEnd">开始</div>
            <img style="width:20px;height:60px;" src="../../assets/jianTou.png" alt="111">
            <el-popover
                placement="right"
                title=""
                width="200"
                trigger="hover"
                v-for="(item) in dataDetailView"
                :key="item.index"
                >
                <h4 v-for="o in item.Details" :key="o.index">{{o.ApprovalObjectName}}</h4>
                <div :class="item.ApprovalObjectType == 0?'activePer':'activeOrg'" slot="reference">{{item.NodeName}}</div>
                <div slot="reference" style="text-align:center;"><img style="width:20px;height:60px;" src="../../assets/jianTou.png" alt="111"></div>
            </el-popover>
            <div class="starAEnd">结束</div>
        </el-dialog>
    </div>
</template>

<script>
import { getApprovalProcessListPage,addApprovalProcess,updateApprovalProcess,getApprovalProcessDiagramByApprovalProcessID,getOrganizationTree,getUserListPage } from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Sortable from 'sortablejs';
import util from "../../../util/date";
export default {
    components: { Toolbar },
    data() {
        return {
            //增删改查按钮
            buttonList: [],
            addOrEdi: true,
            pages: {  //关于分页的obj
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            // 下拉框数据
            filters: {
                ApproName: '',
                selectType: '',
                selectc: ''
            },
            optionsData1: [{  //下拉选择
                value: 0,
                label: '转为字典试题'
            },{
                value: 1,
                label: '变更字典试题'
            },{
                value: 2,
                label: '发布考试项目'
            }],
            optionsData2: [{  //下拉选择
                value: true,
                label: '启用'
            },{
                value: false,
                label: '禁用'
            }],
            listLoading: false,
            // 列表数据
            dataPer: [],
            addFormVisible: false, //新增界面是否显示
            addLoading: false,

            //新增界面数据
            addForm: {
                processName: '',
                selectType: '',
                IsEnable: true,
                remarks: '',
            },
            listLoading1: false,
            roles: [{nodeName:"",nodeNum:1,organTitle:"选择机构",perTitle:"选择人",noPer:true,noOrg:true}],
            selRecName: '',
            addFormRules: {
                processName: [{ required: true, message: "请输入流程名称", trigger: "blur" }],
                IsEnable: [{ required: true, message: "请选择启用标识", trigger: "blur" }],
                selectType: [{ required: true, message: '请选择审批类型', trigger: 'blur' }]
            },
            selectFormVisible: false,  //选择人/组织机构
            // organTitle: '选择机构',
            perOrOrgan: '',
            treeData: [],
            listLoading1: false,
            selectSubmitData: [],
            selectSubmitPer: [],
            selectSubmitOrg: [],
            defaultProps: {
                children: "Children",
                label: "Name",
                id:'ID' //可要可不要
            },
            defaultProps1: {
                children: "Children",
                label: "Name",
                value:'ID' //可要可不要
            },
            listLoading2: false,
            users:[],
            //关于分页的obj
            pages1: {  
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            OptionTreec: [],
            perSelectData: [],
            recordData: [],
            //编辑界面数据
            detailData: '',
            // 详情流程图数据
            dataDetailView: [],
            viewDialog: false,
            isRouterAlive: true,
        }
    },
    methods: {
        // 刷新
        doRefresh() {
            let params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            };
            if(this.filters.selectc !== "00" && this.filters.selectc !== ''){
                params.isEnable = this.filters.selectc
            }
            if(this.filters.selectType !== "00" && this.filters.selectType !== ''){
                params.processType = this.filters.selectType
            }
            if(this.filters.ApproName){
                params.processName = this.filters.ApproName
            }
            this.isRouterAlive = false;            //先关闭，
            this.$nextTick(function () {
                this.isRouterAlive = true;         //再打开
            }) 
            this.getApprovalList(params);
        },
        // 增，删，改，查
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getApprovalProcess() {
            this.pages1.pageIndex = 1
            this.doRefresh();
        },
        // 新增
        handleAdd() {
            this.addOrEdi = true;
            this.addFormVisible = true;
            this.selectFun();
            this.getUsers();
            this.rowDrop();
            this.addForm = {
                processName: '',
                selectType: '',
                IsEnable: true,
                remarks: '',
            }
            this.roles = [{nodeName:"",nodeNum:1,organTitle:"选择机构",perTitle:"选择人",noPer:true,noOrg:true}]
        },
        addSubmit() {
            let _this = this;
            this.$refs.addForm.validate(valid => {
                if (valid) {
                    if(this.roles.length > 0) {
                        var nodeArr = []
                        for(var a = 0; a < this.roles.length; a++) {
                            var nodeObj = {}
                            nodeObj.NodeName = this.roles[a].nodeName
                            nodeObj.SortNo = a
                            if(this.roles[a].details) {
                                if(!this.addOrEdi && this.roles[a].perTitle == "修改人"){
                                    var detailsArr = []
                                    for(var j = 0; j < this.roles[a].details.length;j++){
                                        var detailsObj = {}
                                        detailsObj.ApprovalObjectID = this.roles[a].details[j].Id || this.roles[a].details[j].approvalObjectID
                                        detailsArr.push(detailsObj)
                                    }
                                    nodeObj.Details = detailsArr
                                }else{
                                    nodeObj.Details = this.roles[a].details
                                }
                                if(this.roles[a].organTitle == "修改机构"){
                                    nodeObj.ApprovalObjectType = 1
                                }else if(this.roles[a].perTitle == "修改人"){
                                    nodeObj.ApprovalObjectType = 0
                                }
                            }
                            nodeArr.push(nodeObj)
                        }
                    }
                    var params = {
                        ProcessName: this.addForm.processName,
                        IsEnable: this.addForm.IsEnable,
                        Remarks: this.addForm.remarks,
                        ProcessType: this.addForm.selectType,
                    }
                    if(nodeArr.length > 0) {
                        params.Nodes = nodeArr
                    }
                    if(params.Nodes[params.Nodes.length-1].nodeName == "") {
                        this.$message({
                            message: '请输入第'+params.Nodes.length+'行名称',
                            type: 'warning'
                        });
                        return;
                    }else{
                        if(this.addOrEdi){
                            this.addApproveFlow(params);
                        }else{
                            this.ediApproveFlow(params)
                        }
                        this.addLoading = true;
                    }
                }
            })
        },
        addApproveFlow(params) {  //调用新增api
            addApprovalProcess(params).then(res => {
                var resData = res.data
                if(resData.Success){
                    this.$message({
                        message: resData.Message,
                        type: "success"
                    });
                    this.doRefresh();
                    this.addFormVisible = false;
                    this.addForm = {processName: '',selectType: '',IsEnable: '',remarks: '',}
                    this.roles = [{nodeName:"",nodeNum:1,organTitle:"选择机构",perTitle:"选择人",}]
                }else{
                    this.$message({
                        message: resData.Message,
                        type: "error"
                    });
                }
                this.addLoading = false;
            })
        },
        ediApproveFlow(params) {  //调用编辑api
            params.ID = this.detailData.ID
            updateApprovalProcess(params).then(res => {
                var resData = res.data
                if(resData.Success){
                    this.$message({
                        message: resData.Message,
                        type: "success"
                    });
                    this.doRefresh();
                    this.addFormVisible = false;
                    this.addForm = {processName: '',selectType: '',IsEnable: '',remarks: '',}
                    this.roles = [{nodeName:"",nodeNum:1,organTitle:"选择机构",perTitle:"选择人",}]
                    this.detailData = ""
                }else{
                    this.$message({
                        message: resData.Message,
                        type: "error"
                    });
                }
                this.addLoading = false;
            })
        },
        // 新增节点
        addNodesChi() {
            if(this.roles[this.roles.length-1].nodeName == ""){
                this.$message({
                    message: "请输入第"+this.roles.length+"行名称",
                    type: 'warning'
                });
                return
            }
            var nodesData = {nodeName:"",nodeNum:this.roles.length+1,organTitle:"选择机构",perTitle:"选择人",noPer:true,noOrg:true}
            this.roles.push(nodesData)
        },
        selectCurrentRow(val) {
            if(val){
                this.detailData = val
            }
        },
        // 选择人
        nodesSelPer(val) {
            this.selRecName = val.nodeNum
            this.selectFormVisible = true
            this.perOrOrgan = "per"
            if(val.details && val.details.length > 0){
                var recDataArr = []
                for(var a = 0; a < val.details.length; a++){
                    if(this.recordData.length){
                        for(var i = 0; i < this.recordData.length; i++){
                            if(val.details[a].approvalObjectID == this.recordData[i].Id){
                                var recDataObj = {}
                                recDataObj.Id = this.recordData[i].Id
                                recDataObj.Name = this.recordData[i].Name
                                recDataArr.push(recDataObj)
                                this.perSelectData = recDataArr
                            }
                        }
                    }else{
                        var recDataObj = {}
                        recDataObj.Id = val.details[a].Id
                        recDataObj.Name = val.details[a].Name
                        recDataArr.push(recDataObj)
                        this.perSelectData = recDataArr
                    }
                }
            }else{
                this.perSelectData = [];
            }
            // this.selectFun();
        },
        // dialog关闭
        closeSelect() {
            // this.selectSubmitOrg = [];
            // this.perSelectData = [];
        },
        // 添加人
        nodesSelPer2(val) {
            for(var a = 0; a < this.perSelectData.length; a++) {
                if(this.perSelectData[a].Id == val.ID){
                    this.$message({
                        message: '【' + val.Name + '】' +'已存在！',
                        type: 'warning'
                    })
                    return
                }
            }
            var perDataDetail = {
                Name: val.Name,
                Id: val.ID
            }
            this.perSelectData.push(perDataDetail)
            this.recordData = this.perSelectData
        },
        // 删除已选人
        deleSelPer2(val) {
            for(var j = 0; j < this.perSelectData.length; j++) {
                if(this.perSelectData[j].Id == val.Id){
                    this.perSelectData.splice(j,1)
                    // this.recordData = this.perSelectData
                }
            }
        },
        //选择人列表当前行发生变化时
        selectCurrentRow2(val) {
            this.currentRow = val;
        },
        // 选择机构
        nodesSelOrg(val) {
            var _this = this
            this.selRecName = val.nodeNum
            this.selectFormVisible = true
            this.perOrOrgan = "org"
            // this.selectFun();
            if(val.details && val.details.length > 0){
                var setDataTree = []
                for(var j = 0; j < val.details.length; j++){
                    setDataTree.push(val.details[j].approvalObjectID)
                }
                setTimeout(() => {
                    _this.$refs.tree.setCheckedKeys(setDataTree)
                },50);
            }else{
                setTimeout(() => {
                    _this.$refs.tree.setCheckedKeys([])
                },50);
            }
        },
        selectFun(params) {
            var _this = this
            this.listLoading1 = true
            getOrganizationTree(params).then(res => {
                var data = res.data
                var resData = [data.Response]
                resData.push()
                if(data.Success){
                    this.listLoading1 = false
                    if(data.Response.Name == "根节点"){
                        _this.treeData = resData[0].Children
                    }else{
                        _this.treeData = resData
                    }
                }
            })
        },
        // 查询组织机构
        searchPageList() {
            this.getUsers();
        },
        // 选中树形结构
        getChecked(data) {
            var selData = this.$refs.tree.getCheckedNodes()
            var details = []
            if(selData.length > 0){
                for(var i = 0; i < selData.length; i++){
                    var selDataObj = {
                        approvalObjectID: selData[i].ID
                    }
                    details.push(selDataObj)
                }
            }
            this.selectSubmitOrg = details
            this.selectSubmitData = details
        },
        // 保存
        selectSubmit() {
            if(this.perOrOrgan == "org"){
                if(this.roles.length > 0 && this.selectSubmitData.length > 0){
                    this.selectSubmitOrg = this.selectSubmitData
                    for(var a = 0; a < this.roles.length; a++){
                        if(this.roles[a].nodeNum == this.selRecName){
                            this.roles[a].details = this.selectSubmitData
                            this.roles[a].organTitle = "修改机构"
                            this.roles[a].noPer = false;
                            break;
                        }
                    }
                    // this.organTitle = "修改机构"
                    this.selectFormVisible = false;
                }else{
                    // this.$message({
                    //     message: '还没选择组织机构',
                    //     type: 'warning'
                    // });
                    this.selectSubmitOrg = this.selectSubmitData
                    for(var a = 0; a < this.roles.length; a++){
                        if(this.roles[a].nodeNum == this.selRecName){
                            this.roles[a].details = this.selectSubmitData
                            this.roles[a].organTitle = "选择机构"
                            this.roles[a].noPer = true;
                            break;
                        }
                    }
                    // this.organTitle = "修改机构"
                    this.selectFormVisible = false;
                }
            }else if(this.perOrOrgan == "per"){
                if(this.roles.length > 0 && this.perSelectData.length > 0){
                    this.selectSubmitPer = this.perSelectData
                    for(var a = 0; a < this.roles.length; a++){
                        if(this.roles[a].nodeNum == this.selRecName){
                            var perDataArr = []
                            for(var i = 0; i < this.perSelectData.length; i++){
                                var perDataObj = {}
                                perDataObj.approvalObjectID = this.perSelectData[i].Id
                                perDataArr.push(perDataObj)
                            }
                            // this.roles[a].details = this.perSelectData
                            this.roles[a].details = perDataArr
                            this.roles[a].perTitle = "修改人"
                            this.roles[a].noOrg = false;
                            break;
                        }
                    }
                    // this.organTitle = "修改机构"
                    this.selectFormVisible = false;
                }else{
                    // this.$message({
                    //     message: '还没选择用户',
                    //     type: 'warning'
                    // });
                    this.selectSubmitPer = this.perSelectData
                    for(var a = 0; a < this.roles.length; a++){
                        if(this.roles[a].nodeNum == this.selRecName){
                            var perDataArr = []
                            for(var i = 0; i < this.perSelectData.length; i++){
                                var perDataObj = {}
                                perDataObj.approvalObjectID = this.perSelectData[i].Id
                                perDataArr.push(perDataObj)
                            }
                            // this.roles[a].details = this.perSelectData
                            this.roles[a].details = perDataArr
                            this.roles[a].perTitle = "选择人"
                            this.roles[a].noOrg = true;
                            break;
                        }
                    }
                    // this.organTitle = "修改机构"
                    this.selectFormVisible = false;
                }
            }
        },
        // 删除字节的
        nodesDel(val,index) {
            if(this.roles.length > 1){
                this.roles.splice(index,1)
            }else{
                this.$message({
                    message: '至少保留一项！',
                    type: 'warning'
                });
            }
        },
        // 编辑
        handleEdit() {
            this.addOrEdi = false;
            this.selectFun();
            this.getUsers();
            this.rowDrop();
            if(this.detailData !== ''){
                this.addForm = {
                    processName: this.detailData.ProcessName,
                    selectType: this.detailData.ProcessType,
                    // selectType: 1,
                    IsEnable: this.detailData.IsEnable,
                    remarks: this.detailData.Remarks,
                }
                var callNodes = this.detailData.Nodes
                if(callNodes && callNodes.length > 0){
                    var rolesArr = []
                    for(var a = 0; a < callNodes.length; a++){
                        var rolesObj = {}
                        rolesObj.nodeName = callNodes[a].NodeName
                        rolesObj.nodeNum = callNodes[a].SortNo
                        if(callNodes[a].ApprovalObjectType == 0){
                            rolesObj.noOrg = false;
                            rolesObj.noPer = true;
                            rolesObj.organTitle = "选择机构";
                            rolesObj.perTitle = "修改人";
                            var ediPerDataArr = []
                            for(var i = 0; i < callNodes[a].Details.length; i++){
                                var ediOrgDataObj = {}
                                ediOrgDataObj.Id = callNodes[a].Details[i].ApprovalObjectID
                                ediOrgDataObj.Name = callNodes[a].Details[i].ApprovalObjectName
                                ediPerDataArr.push(ediOrgDataObj)
                            }
                            rolesObj.details =ediPerDataArr
                        }else if(callNodes[a].ApprovalObjectType == 1){
                            rolesObj.noOrg = true;
                            rolesObj.noPer = false;
                            rolesObj.organTitle = "修改机构";
                            rolesObj.perTitle = "选择人";
                            var ediOrgDataArr = []
                            for(var i = 0; i < callNodes[a].Details.length; i++){
                                var ediOrgDataObj = {}
                                ediOrgDataObj.approvalObjectID = callNodes[a].Details[i].ApprovalObjectID
                                ediOrgDataArr.push(ediOrgDataObj)
                            }
                            rolesObj.details =ediOrgDataArr
                        }
                        
                        rolesArr.push(rolesObj)
                    }
                    this.roles = rolesArr
                }
                this.addFormVisible = true
            }else{
                this.$message({
                    message: '请选择要编辑的一行数据！',
                    type: 'warning'
                })
            }
        },
        // 获取列表
        getApprovalList(params) {
            this.listLoading = true;
            getApprovalProcessListPage(params).then(res => {
                var resData = res.data
                if(resData.Success){
                    this.listLoading = false;
                    this.dataPer = resData.Response.Data
                    this.pages.dataCount = resData.Response.DataCount
                    this.pages.pageIndex = resData.Response.PageIndex
                    if(res.data.Response.PageSize > 0){
                        this.pages.pageSize = resData.Response.PageSize
                    }
                }else{
                    this.$message({
                        message: resData.Message,
                        type: "error"
                    });
                }
            });
        },
        //获取用户列表
        getUsers(){
            this.listLoading2 = true;
            let params = {
                pageIndex: this.pages1.pageIndex,
                pageSize: this.pages1.pageSize,
            };
            if(this.OptionTreec.length > 0){
                params.organizationInfoID = this.OptionTreec[this.OptionTreec.length - 1]
            }
            getUserListPage(params).then((res) => {
                this.users = res.data.Response.Data;
                this.listLoading2 = false;
                this.pages1.dataCount = res.data.Response.DataCount
                this.pages1.pageIndex = res.data.Response.PageIndex
                if(res.data.Response.PageSize > 0){
                    this.pages1.pageSize = res.data.Response.PageSize
                }
            });
        },
        // 分页
        handleCurrentChange1(val) {
            this.pages1.pageIndex = val;
            this.getUsers();
        },
        handleSizeChange1(val){
            this.pages1.pageSize = val
            this.getUsers();
        },
        // 查看
        handleClick(val) {
            this.addForm = {
                processName: val.ProcessName,
                selectType: val.ProcessType,
                // selectType: 1,
                IsEnable: val.IsEnable,
                remarks: val.Remarks,
            }
            var valId = val.ID
            this.viewDialog = true;
            this.handDataDetail(valId);
        },
        handDataDetail(valId) {
            var params = {
                approvalProcessID: valId
            }
            getApprovalProcessDiagramByApprovalProcessID(params).then(res => {
                var resData = res.data
                if(resData.Success){
                    this.dataDetailView = resData.Response
                }else{
                    this.$message({
                        message: resData.Message,
                        type: "error"
                    });
                }
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.doRefresh();
        },

        handleSizeChange(val){
            this.pages.pageSize = val
            this.doRefresh();
        },
        //行拖拽
        rowDrop() {
            const _this = this
            setTimeout(() => {
                if(document.querySelector('.nodeChiTable .el-table__body tbody')){
                    const tbody = document.querySelector('.nodeChiTable .el-table__body tbody')
                    draggable: ".el-table__row",
                    Sortable.create(tbody, {
                        onEnd({ newIndex, oldIndex }) {
                            const currRow = _this.roles.splice(oldIndex, 1)[0]
                            _this.roles.splice(newIndex, 0, currRow)
                        }
                    })
                }
            })
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    mounted() {
        this.doRefresh();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .nodeChiTable>>>.el-input__inner {
        border 0;
        height 30px!important;
    }
    .nodeChiTable>>>.el-table__fixed-right{
        padding-bottom 18px!important;
    }
    .perOrgCard>>>.el-timeline-item__timestamp{
        color #000;
        font-size 16px;
        // font-weight bold;
    }
    .viewFlow>>>.el-dialog__body{
        width 100px;
        margin 0 auto;
        display flex;
        flex-direction column;
        align-items center;
    }
    .text {
        font-size 14px;
    }
    .item {
        margin-bottom 18px;
    }
    .clearfix:before,
    .clearfix:after {
        display table;
        content "";
    }
    .clearfix:after {
        clear both
    }
    .box-card {
        // width 480px;
    }
    .el-card {
        width 100%!important;
    }
    .dropBtn{
        border 1px solid #fff!important;
    }
    .activePer{
        width 150px;
        padding 15px 0;
        margin 15px 0;
        background #3296FA;
        text-align: center;
        border-radius 5px;
        color #fff;
        font-weight bold;
        font-size 16px;
    }
    .activeOrg{
        width 150px;
        padding 15px 0;
        margin 15px 0;
        background #FF943E;
        text-align: center;
        border-radius 5px;
        color #fff;
        font-weight bold;
        font-size 16px;
    }
    .starAEnd{
        font-weight bold;
        font-size 16px;
        margin 15px 0;
        width 90px;
        height 50px;
        background #fff;
        text-align center;
        line-height 50px;
        border-radius 50%;
        color #000
        box-shadow: 1px 3px 9px #ccc;
    }
</style>